/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/ban-types */
import "trix/dist/trix"
import "trix/dist/trix.css"
import "../../../static/trix.scss"

import { TrixEditor } from "react-trix"

import React, { useEffect, useRef, useState } from "react"
import { Page } from "../../components/Page"
import Form from "react-bootstrap/esm/Form"
import Button from "react-bootstrap/esm/Button"
import HCaptcha from "@hcaptcha/react-hcaptcha"
import { deleteDraft, getDraft, saveDraft } from "../../db"
import {
  useAnchorWallet,
  useConnection,
  useWallet,
} from "@solana/wallet-adapter-react"
import { navigate } from "gatsby"
import CryptoJS from "crypto-js"
import { createSignature } from "../../helpers/signMessage"
import { uploadFile } from "../../helpers/file"

const events = {
  "trix-attachment-add": async (e: any) => {
    const file = e.attachment.file
    if (file) {
      console.log(file)
      // Upload and get image url
      const result = await uploadFile(file)
      console.log(result.url)
      e.attachment.setAttributes({ url: result.url })
    }
  },
  "trix-file-accept": (e: any) => {
    if (!e.file.type.includes("image/")) {
      alert("Only image attachments are allowed for now.")
      e.preventDefault()
    }
  },
}

export default function Editor(params: { "*": string }) {
  const [content, setContent] = useState("")
  const [draftMessage, setDraftMessage] = useState("")
  const [editor, setEditor] = useState<any>(null)
  const [saveMessage, setSaveMessage] = useState("")
  const [hCaptchaToken, setHCaptchaToken] = useState("")
  const [draftId, setDraftId] = useState(0)
  const [draftLoaded, setDraftLoaded] = useState(false)
  const titleRef = useRef(null)
  const hcaptchaRef = useRef<any>(null)
  const editorRef = useRef(null)

  const { publicKey, signMessage } = useWallet()
  const { connection } = useConnection()
  const wallet = useAnchorWallet()

  const save = async () => {
    let signature
    const useLedger =
      localStorage.getItem("useLedger") === "true" ? true : false

    try {
      signature = await createSignature(
        connection,
        wallet,
        publicKey,
        signMessage,
        CryptoJS.SHA256(content).toString()
      )
    } catch (e: any) {
      return setSaveMessage(e.message)
    }

    const res = await fetch(`${process.env.GATSBY_BACKEND_ENDPOINT}document`, {
      method: "POST",
      body: JSON.stringify({
        // @ts-ignore
        title: titleRef.current!.value,
        content,
        hcaptchaToken: hCaptchaToken,
        signature,
        publicKey: (publicKey || "").toString(),
        ledger: useLedger,
      }),
    })

    hcaptchaRef.current!.resetCaptcha()
    setHCaptchaToken("")

    const result = await res.json()
    if (result.status === "success") {
      // Remove draft and redirect to page
      await deleteDraft(draftId)
      return navigate(`/d/${result.slug}`)
    } else if (result.error) {
      setSaveMessage(result.error)
    }
  }

  if (typeof window === "undefined") {
    return null
  }

  useEffect(() => {
    // Set draft ID
    const draftId = params["*"]

    setDraftId(parseFloat(draftId) || Math.random())
  }, [params])

  useEffect(() => {
    if (editor)
      Object.entries(events).forEach(([name, fn]) =>
        editor.element.addEventListener(name, fn)
      )
    return () =>
      Object.entries(events).forEach(([name, fn]) =>
        editor?.element.removeEventListener(name, fn)
      )
  }, [editor])

  // Load draft on page load
  useEffect(() => {
    if (!editorRef || !draftId) {
      return
    }

    getDraft(draftId).then(draft => {
      if (!draft || draftLoaded) return
      setDraftLoaded(true)
      // Set draft
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      titleRef.current!.value = draft.title
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      editorRef.current!.editor.insertHTML(draft.content)
    })
  }, [editorRef, draftId])

  // Draft saver
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (content) {
        saveDraft(
          draftId,
          (titleRef.current as unknown as { value: string })?.value,
          content
        ).then(() =>
          setDraftMessage(`Draft saved at ${new Date().toISOString()}`)
        )
      }
    }, 400)

    return () => clearTimeout(delayDebounceFn)
  }, [content])

  return (
    <>
      <Page>
        <Form.Group className="mb-3">
          <Form.Label>
            <h1>Document title</h1>
          </Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter document title"
            ref={titleRef}
          />
        </Form.Group>
        <h3>Content</h3>
        <TrixEditor
          onChange={setContent}
          onEditorReady={setEditor}
          ref={editorRef}
          mergeTags={[]}
          autoFocus={true}
          placeholder="Type content here..."
        />
        <br />
        <HCaptcha
          sitekey={process.env.GATSBY_HCAPTCHA_SITE_KEY!}
          onVerify={(token, ekey) => setHCaptchaToken(token)}
          ref={hcaptchaRef}
        />
        <Button
          onClick={async () => {
            await save()
          }}
        >
          Publish
        </Button>{" "}
        <Button
          variant="danger"
          onClick={async () => {
            if (!confirm("Sure?")) return
            await deleteDraft(draftId)
            navigate("/drafts")
          }}
        >
          Delete draft
        </Button>
        <br />
        {saveMessage || null}
        <br />
        <em>{draftMessage}</em>
      </Page>
    </>
  )
}
